#!/usr/bin/env node
/**
 * Represents the authentication routes for the Vue Router.
 * @module auth
 */
import Login from '../views/auth/Login';

/**
 * Represents the authentication routes configuration
 * @type {Array}
 */
const auth = [
  /**
   * Represents the Login route.
   * This route is used for the login page.
   * @type {Object}
   */
  {
    path: '/auth/login',
    name: 'LoginView',
    component: Login
  },
  /**
   * Represents the Reset Password route.
   * This route is used for resetting the password.
   * @type {Object}
   */
  {
    path: '/auth/reset-password',
    name: ''
  }
]

export default auth;

<template>
  <div>
    404 Error: Page Not Found.
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style>

</style>
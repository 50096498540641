#!/usr/bin/env node
/**
 * Creates a Vue Router instance with defined routes.
 * @module router
 */
import { createRouter, createWebHashHistory } from 'vue-router';
import NotFound from '../views/NotFound.vue';
import def_route from './def.routes';
import auth from './auth.routes';

/**
 * Represents the routes configuration for the Vue router.
 * @type {Array}
 */
const routes = [
  ...auth,
  ...def_route,
  /**
   * Represents the 'NotFound' route
   * This route is used when the user accesses a route that doesn't match any defined routes.
   * @type {Object}
   */
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound 
  }
]

/**
 * Creates a Vue Router instance with the specified configurations.
 * @type {Object}
 */
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;

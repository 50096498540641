<template>
  <ComingSoon class="w-full h-full"/>
  <!-- <div class="bg-gray-100 h-screen  relative">
    <header class="border-b hover:shadow absolute top-0 left-0 right-0">
      <Header />
    </header>

    <body class="h-full pt-24">
      <router-view />
    </body>

    <div class="absolute bottom-0 left-0 right-0 bg-orange-100">
      <footer class="p-8">
        <Footer />
      </footer>
    </div>
  </div> -->
</template>

<script>
// import Header from './components/Header';
// import Footer from './components/Footer';
import ComingSoon from './views/ComingSoon';

export default {
  name: 'App',
  components: {
    // Header,
    // Footer,
    ComingSoon
  }
}
</script>

<style>
</style>

<template>
  <div class="flex flex-col min-h-dvh bg-gradient animate-gradient">
    <header class="px-4 lg:px-6 h-14 flex items-center justify-between">
      <router-link to="#" class="flex items-center gap-2">
        <CloudLightningIcon class="size-6 text-white animate-pulse" />
        <span class="text-2xl font-bold text-white font-sans">Precise Elecsys</span>
      </router-link>
    </header>
    <main class="flex-1 flex flex-col items-center justify-center px-4 md:px-6 text-center space-y-6">
      <div class="space-y-4 animate-fadeIn">
        <CloudLightningIcon class="size-12 text-white animate-bounce" />
        <h1 class="text-5xl font-bold text-white font-sans">Coming Soon</h1>
        <p class="text-xl text-white font-sans">Powering your future with expert electrical and IT Tech solutions.</p>
      </div>
      <div class="w-full max-w-md space-y-4 animate-fadeIn">
        <p class="text-white font-sans">
          Precise Elecsys provides comprehensive electrical installations, IT support, and expert consulting services
          to businesses of all sizes. Stay tuned for our upcoming launch!
        </p>

        <form class="flex gap-2" @submit.prevent="handleSubmit">
          <input
            type="email"
            v-model="email"
            placeholder="Join Waitlist"
            class="flex-1 bg-white/20 text-white placeholder:text-white/50 focus:ring-white outline-none focus:ring-[1px] font-sans p-2 rounded"
          />
          <button type="submit" class="cursor-pointer hover:animate-none hover:transition-all hover:duration-300 hover:ease-in-out text-white animate-bounce font-sans p-2 rounded">
            <EmailIcon class="size-12 text-white hover:text-green-500" />
          </button>
        </form>
        <Toaster richColors />
        <!-- <div v-if="showToast" class="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-red-500 text-white px-4 py-2 rounded-md shadow-md">
          Please enter a valid email address.
        </div> -->
      </div>
    </main>
    <footer class="px-4 lg:px-6 h-14 flex items-center justify-between border-t border-white/20 text-white">
      <p class="text-sm font-sans">&copy; 2024 Precise Elecsys</p>
      <nav class="flex gap-4">
        <router-link to="#" class="text-sm hover:underline animate-fadeIn font-sans">Privacy</router-link>
        <router-link to="#" class="text-sm hover:underline animate-fadeIn font-sans">Terms</router-link>
      </nav>
    </footer>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { Toaster, toast } from 'vue-sonner';

const EmailIcon = defineComponent({
  name: 'EmailIcon',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
    </svg>`
});

const CloudLightningIcon = defineComponent({
  name: 'CloudLightningIcon',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M6 16.326A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 .5 8.973" />
      <path d="m13 12-3 5h4l-3 5" />
    </svg>
  `
});

export default {
  name: 'ComingSoon',
  components: {
    CloudLightningIcon, EmailIcon,
    Toaster
  },
  setup() {
    const email = ref('');
    const showToast = ref(false);
    let base_url;

    const handleSubmit = async () => {
      if (window.origin.includes('localhost') || window.origin.includes('0.0.0.0')) {
        base_url = 'http://localhost:5000/';
      } else if (window.origin.includes('elecsys')) {
        base_url = 'https://elecsys-general.onrender.com/';
      }

      const emailString = email.value;

      const isValidEmail = (value) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(value);
      };

      if (!isValidEmail(emailString)) {
        toast.error('Please enter a valid email address.');
        return;
      }
      return fetch(`${base_url}api/v1/waitlist`, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: emailString })
      })
      .then(res => {
        if (!res.ok) {
          return res.json().then(errorData => {
            const error = new Error(res.statusText);
            error.data = errorData;
            throw error;
          });
        }
        return res.json();
      })
      .then(() => {
        toast.success('Successfully Joined Waitinglist');
      })
      .catch((error) => {
        if (error.data && error.data.error) {
          toast.error(`${error.data.error}`);
        } else {
          toast.error('An unknown error occurred');
        }
      });
    };

    return {
      email,
      handleSubmit,
      showToast,
      toast
    };
  }
};
</script>

<style scoped>
.size-6 {
  width: 1.5rem;
  height: 1.5rem;
}

.size-12 {
  width: 3rem;
  height: 3rem;
}

.animate-pulse {
  animation: pulse 2s infinite;
}

.animate-bounce {
  animation: bounce 1s infinite;
}

.animate-fadeIn {
  animation: fadeIn 1.5s ease-in;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.bg-gradient {
  background: linear-gradient(135deg, #0077b6, #00b894);
  background-size: 400% 400%;
  animation: gradientAnimation 10s ease infinite;
}

input {
  padding: 0.5rem;
  border-radius: 0.25rem;
}

button {
  padding: 0.5rem;
  border-radius: 0.25rem;
}

button:hover {
  transition: background-color 0.3s ease-in-out;
}
</style>

#!/usr/bin/env node
/**
 * Represents the default routes for the Vue Router.
 * 
 * @type {Array}
 */

import Home from '../views/Home';
import About from '../views/About';

/**
 * Represents the default routes configuration.
 * 
 * @type {Array}
 */
const def_routes = [
  /**
   * Represents the Home Route.
   * This route is used for the home page.
   * @type {Object}
   */
  {
    path: '/',
    component: Home
  },
  /**
   * Represents the About route.
   * This route is used for the About page.
   * @type {Object}
   */
  {
    path: '/about',
    component: About
  }
]

export default def_routes;
